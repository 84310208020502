<template>
    <section class="key-features">
        <h1 class="h0">
            Key features
        </h1>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg mb-4 mb-lg-0">
                    <div class="feature-card">
                        <figure>
                            <img
                                src="@assets/img/founders-landing/key-features/complex-ideas.jpg"
                                srcset="@assets/img/founders-landing/key-features/complex-ideas.jpg 1x, @assets/img/founders-landing/key-features/complex-ideas.jpg 2x"
                                alt="Complex ideas"
                            >
                        </figure>
                        <div class="feature-card-body">
                            <h2>Complex ideas quickly understood</h2>
                            <p>The unique 10-point structure of our Memos means no noise or wasted time, just concentrated wisdom.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg mb-4 mb-lg-0">
                    <div class="feature-card">
                        <figure>
                            <img
                                src="@assets/img/founders-landing/key-features/create-and-share.jpg"
                                srcset="@assets/img/founders-landing/key-features/create-and-share.jpg 1x, @assets/img/founders-landing/key-features/create-and-share.jpg 2x"
                                alt="Complex ideas"
                            >
                        </figure>
                        <div class="feature-card-body">
                            <h2>Create and share Memos</h2>
                            <p>Let family, friends, colleagues and the wider world benefit from your knowledge.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg mb-4 mb-lg-0">
                    <div class="feature-card">
                        <figure>
                            <img
                                src="@assets/img/founders-landing/key-features/save-and-organize.jpg"
                                srcset="@assets/img/founders-landing/key-features/save-and-organize.jpg 1x, @assets/img/founders-landing/key-features/save-and-organize.jpg 2x"
                                alt="Complex ideas"
                            >
                        </figure>
                        <div class="feature-card-body">
                            <h2>Save and organize</h2>
                            <p>Memo'd makes your most important ideas simpler to communicate – and easier to find. Keep your valuable insights safely stored in one place.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "KeyFeatures"
}
</script>

<style lang="scss" scoped>
.key-features {
    padding: 120px 0;
    padding-top: 0;

    @media (max-width: $md) {
        padding: 30px 0;
    }

    h1 {
        margin-bottom: 65px;
        text-align: center;
        color: $primary-color;

        @media(max-width: $xl) {
            text-align: center;
        }
    }

    .feature-card {
        background-color: #272729;
        border-radius: 12px;
        overflow: hidden;
        height: 100%;
        max-width: 320px;

        @media(max-width: $xl) {
            margin: 0 auto;
        }

        figure {
            height: 200px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .feature-card-body {
            padding: 25px;
            padding-top: 15px;

            h2 {
                color: #05A88F;
                margin-bottom: 10px;
            }

            p {
                @extend .body-text;
                line-height: 24px;
                color: white;
                margin-bottom: 0;
            }
        }
    }
}
</style>
